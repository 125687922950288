//= require moment

$(document).on("turbolinks:load", () => {
  $('.delete_entity_note').click(function(e) {
    e.preventDefault();
    var target = $(e.target);
    $(target).closest("div.timeline-footer").find("input.destroy").val("1");
    $(target).closest("div.timeline-item-wrapper").fadeOut("slow");
  });

  $('.edit_entity_note').click(function(e) {
      e.preventDefault();
      var target = $(e.target);
      $(target).closest("div.timeline-item").find("div.timeline-body").hide();
      $(target).closest("div.button-bar").hide();
      $(target).closest("div.timeline-footer").find("p").fadeIn(); 
  });

  $('.cancel_edit_entity_note').click(function(e) {
      e.preventDefault();
      var target = $(e.target);
      $(target).closest("p").hide();
      $(target).closest("div.timeline-item").find("div.timeline-body").fadeIn();
      $(target).closest("div.timeline-footer").find("div.button-bar").show();
  });
});