import consumer from "./consumer"

consumer.subscriptions.create("MatchKeywordsChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.content.type == 'match_keywords_finished') {
      var message = 'Match Keywords process has finished.<br/>Total Matched:<br/>' +
                data.content.total_matched.map(match => {
                  return `- ${match[0]}: ${match[1]}`;
                  }).join("<br/>");
      toastr.success(message);
    } else if (data.content.type == 'match_keywords_error') {
      var message = 'Match Keywords process has finished with errors.<br/>Errors:<br/>' +
                data.content.errors.map(error => {
                  return `- ${error}`;
                  }).join("<br/>");
      toastr.error(message);
    }
  }
});
