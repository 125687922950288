import consumer from "./consumer"

consumer.subscriptions.create("RankingReportsChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.content.type == 'ranking_report_published') {
      $(`#ranking_report_${data.content.id}`).find("div.report_url").html(
        `<a target='_blank' class='action-btn' href='${data.content.report_url}'>Open</a>`
      );
      toastr.success('Report published successfully');
    } else if (data.content.type == 'ranking_report_error') {
      toastr.error(data.content.error, 'Ranking Report');
      $(`#ranking_report_${data.content.id}`).find("div.report_url").html(
        ``
      );
    } else if (data.content.type == 'ranking_report_unpublished') {
      $(`#ranking_report_${data.content.id}`).find("div.report_url").html(
        ``
      );
      toastr.success('Report unpublished successfully');
    }
  }
});
