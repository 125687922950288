$(document).on("turbolinks:load", () => {
  $("#dropdownSearch").on('keyup', function () {
    var search = $(this).val().toLowerCase();

    $(".dropdown-item").each(function () {
      if ($(this).html().toLowerCase().indexOf(search) != -1) {
        $(this).show();
      }
      else {
        $(this).hide();
      }

    });
  });
});