import consumer from "./consumer"

consumer.subscriptions.create("IdentifyCarriersChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.content.type == 'identify_carriers_finished') {
      var message = 'Identify carriers process has finished.';
      toastr.success(message);
    } else if (data.content.type == 'identify_carriers_error') {
      var message = 'Identify Carriers process has finished with errors.<br/>Errors:<br/>' +
                data.content.errors.map(error => {
                  return `- ${error}`;
                  }).join("<br/>");
      toastr.error(message);
    }
  }
});
