$(document).on("turbolinks:load", () => {
  $('#copy_to_clipboard').click(function(e) {
    var tempElement = $("<input>");
    $("body").append(tempElement);
    tempElement.val($('span.gd_client_email').first().text());
    tempElement.select();
    document.execCommand("Copy");
    tempElement.remove();
  });

  $('#launch_cluster_designer').click(function(e) {
    e.preventDefault();
    const graphCreatorElement = document.getElementById('graph_creator');
    if (graphCreatorElement != null) {
      let initialData = $('#cluster_report_cluster_design_data').val();
      initialData = (initialData != null && initialData != "") ? JSON.parse(initialData) : null;
      GraphCreator.createGraph(
        graphCreatorElement,
        initialData,
        function(data) {
          $('#cluster_report_cluster_design_data').val(JSON.stringify(data));
          $("#clusterDesignerModal").modal('hide');
        },
        function() {
          GraphCreator.removeGraph(graphCreatorElement);
          graphCreatorElement.replaceChildren();
          $("#clusterDesignerModal").modal('hide');
        }
      );
    }

    $("#clusterDesignerModal").modal('show');
  });
});
