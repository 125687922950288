// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()
require("trix")
require("@rails/actiontext")
require("packs/custom/cases")
require("packs/custom/entities")
require("packs/custom/cluster_reports")
require("packs/custom/add_report_to_case")
require("packs/custom/hidden_logo_container")
require("packs/custom/filter")
require("packs/custom/toggle_attach")

window.Rails = Rails

import 'bootstrap'
import 'data-confirm-modal'

import moment from 'moment'
window.moment = moment

global.toastr = require("toastr")

require('admin-lte')
require('select2')
require('daterangepicker')

toastr.options = {
  "closeButton": true,
  "newestOnTop": false,
  "positionClass": "toast-top-right",
  "preventDuplicates": false,
  "onclick": null,
  "showDuration": "300",
  "hideDuration": "1000",
  "timeOut": "6000",
  "extendedTimeOut": "1000",
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

$(document).on("turbolinks:load", () => {
  $('[data-toggle="tooltip"]').tooltip({ boundary: 'window' });
  $('[data-toggle="popover"]').popover();
  $('.select2').select2({});

  $('#account_industries').select2({
    tags: true
  });

  $(".datepicker").daterangepicker({
    locale: {
      format: "YYYY/MM/DD",
    },
    autoclose: true,
    todayBtn: false,
    timePicker: false,
    singleDatePicker: true
  });

  $(".datepicker_filter").daterangepicker({
    locale: {
      format: "YYYY-MM-DD",
    },
    autoclose: true,
    todayBtn: false,
    timePicker: false,
    singleDatePicker: true,
    autoUpdateInput: false
  }, function (chosen_date) {        
    $(this.element[0]).val(chosen_date.format('YYYY-MM-DD'));
  });

  var activePage = stripTrailingSlash(window.location.pathname);
  $('.nav-sidebar li a').each(function(){
    var currentPage = stripTrailingSlash($(this).attr('href'));
    if (activePage == currentPage) {
      $(this).closest('.has-treeview').addClass('active menu-open');
      $(this).parent().addClass('active menu-open');
    }
  });
  
  $(".sidebar .nav-item.expandable > a").on("click", function (e) {
    e.preventDefault();
    var tree =  $(this).closest('.has-treeview').find(".nav-treeview");
    $(tree).show();
  });
});

function stripTrailingSlash(str) {
  if (str.substr(-1) == '/') { return str.substr(0, str.length - 1); }
  return str;
}