import consumer from "./consumer"

consumer.subscriptions.create("QuickSearchEntitiesChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.content.type == 'quick_search_entities_ready') {
      toastr.success('Quick Search Entities has finished');
      if (data.content.html != null) {
        $(`#entities_results`).html(data.content.html);
        $('html, body').animate({
          scrollTop: $(`#entities_results`).offset().top
        }, 1000);
      } else if (data.content.csv != null) {
        $(`#entities_results`).html('');
        var blob = new Blob([data.content.csv], {type: 'text/csv'});
        var url = window.URL.createObjectURL(blob);
        var a = document.createElement('a');
        a.href = url;
        a.download = 'quick_search_entities.csv';
        document.body.append(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL;
      } else {
        toastr.success('Results sent to your email. Please check your inbox.');
        $(`#entities_results`).html('');
      }
    }
  }
});
