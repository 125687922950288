import consumer from "./consumer"

consumer.subscriptions.create("FetchAlibabaSellersChannel", {
  connected() {
  },

  disconnected() {
  },

  received(data) {
    if (data.content.type == 'fetch_alibaba_sellers_ready') {
      toastr.success('Fetching Alibaba Sellers has finished');
      $(`#seller_results`).html(data.content.html);
    }
  }
});
