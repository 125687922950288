//= require moment

$(document).on("turbolinks:load", () => {
  $('#case_country_ids').select2();

  $('.delete_note').click(function(e) {
    e.preventDefault();
    var target = $(e.target);
    $(target).closest("div.timeline-footer").find("input.destroy").val("1");
    $(target).closest("div.timeline-item-wrapper").fadeOut("slow");
  });

  $('.edit_note').click(function(e) {
      e.preventDefault();
      var target = $(e.target);
      $(target).closest("div.timeline-item").find("div.timeline-body").hide();
      $(target).closest("div.button-bar").hide();
      $(target).closest("div.timeline-footer").find("p").fadeIn(); 
  });

  $('.cancel_edit_note').click(function(e) {
      e.preventDefault();
      var target = $(e.target);
      $(target).closest("p").hide();
      $(target).closest("div.timeline-item").find("div.timeline-body").fadeIn();
      $(target).closest("div.timeline-footer").find("div.button-bar").show();
  });

  $('.timestring').each(function() {
      this.textContent = moment(this.textContent).format('lll');
  });

  $('.add_fields').click(function(e) {
      e.preventDefault();
      var link = e.target;
      if (link.dataset.fields === undefined) {
        link = e.target.closest('[data-fields]')
      }

      let time = new Date().getTime();
      let linkId = link.dataset.id;
      let regexp = linkId ? new RegExp(linkId, 'g') : null ;
      let newFields = regexp ? link.dataset.fields.replace(regexp, time) : null;
      newFields ? link.insertAdjacentHTML('beforebegin', newFields) : null;

  });

  $('.form-group').on('click','.remove_fields', function(e) {
    e.preventDefault();
    var link = e.target;
    let fieldParent = link.closest('.nested-fields');
    let deleteField = fieldParent ? fieldParent.querySelector('input[type="hidden"]') : null;
    if (deleteField) {
      deleteField.value = 1;
    }
    if (fieldParent) {
      fieldParent.style.display = 'none';
      if ($(link).closest('span.remove_fields').data('remove') !== undefined) {
        fieldParent.remove();
      }
    }
  });
});