document.addEventListener('DOMContentLoaded', function () {
  const sourceInputs = document.querySelectorAll('input[name$="[source]"]');

  sourceInputs.forEach(sourceInput => {
    const form = sourceInput.closest('form');
    const attachButton = form.querySelector('input[type="submit"][value="Attach"]');

    function toggleAttachButton() {
      attachButton.disabled = sourceInput.value.trim() === '';
    }

    sourceInput.addEventListener('input', toggleAttachButton);

    toggleAttachButton();
  });
});